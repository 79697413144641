import axios from "axios";
export default {
    getSoftwareCategories() {
        return axios({
            method: "GET",
            url: `/api/render-software/get-software-categories`,
            withCredentials: true,
        });
    },
    setModelingSoftware(userId, softwareId) {
        return axios({
            method: "GET",
            url: `/api/render-software/set-modeling-software/${userId}/${softwareId}`,
            withCredentials: true,
        });
    },
    setRendererSoftware(userId, softwareId) {
        return axios({
            method: "GET",
            url: `/api/render-software/set-renderer-software/${userId}/${softwareId}`,
            withCredentials: true,
        });
    },
    setModelingRendererSoftware(userId, softwareModelingId, softwareRendererId) {
        return axios({
            method: "POST",
            url: `/api/render-software/set-modeling-renderer-software`,
            data: {
                userId: userId,
                softwareModelingId: softwareModelingId,
                softwareRendererId: softwareRendererId
            },
            withCredentials: true,
        });
    }
}