<template>
    <div class="w-100">
        <table v-if="elements.length > 0"
               class="flex-fill table table-striped table-head-fixed text-nowrap table-borderless">
            <thead style="font-size: 0.85rem">
                <tr style="color: #1976d2; font-weight: 500">
                    <th class="text-center text-nowrap"
                        style="width: 4rem; font-weight: 600">
                        <div v-if="selectItemToggle">
                            <el-checkbox :indeterminate="isIndeterminate"
                                         v-model="selectionModel.isCheckAll"
                                         @change="handleCheckAllChange"
                                         class="m-0 p-0" size="medium"
                                         style="transform: scale(1.4)" />
                        </div>
                    </th>
                    <th style="width: 10.5rem; font-weight: 600">
                        <button>
                            <span>User</span>
                        </button>
                    </th>
                    <th style="width: 1%; font-weight: 600">
                        <div><span>Recharged</span><span> / Balance</span></div>
                    </th>
                    <th style="width: 1%; font-weight: 600">
                        <button>
                            <span>User information</span>
                        </button>
                    </th>
                    <th style="width: 1%; font-weight: 600">
                        <button>
                            <span>Source / Team / Software</span>
                        </button>
                    </th>
                    <th class="text-center text-nowrap"
                        style="width: 1%; font-weight: 600">
                        <button>
                            <span>Staff</span>
                        </button>
                    </th>
                    <th class="text-center text-nowrap"
                        style="font-weight: 600">
                        <div>
                            <span>Note</span>
                        </div>
                    </th>
                    <th class="text-right text-nowrap"
                        style="width: 1%; font-weight: 600">
                        <button>
                            <span>Last activity</span>
                        </button>
                    </th>
                    <th class="text-right text-nowrap"
                        style="width: 4rem; font-weight: 600"></th>
                </tr>
            </thead>
            <tbody style="color: #616161; font-size: 0.85rem">
                <tr :key="element.id"
                    v-for="element in elements"
                    v-bind:class="getClassBind(element)"
                    v-waypoint="{ active: true, callback: ({ going, direction }) => { onWaypoint({ going, direction, element }) }, options: $intersectionOptions }">
                    <td class="v-center text-center pt-2 pb-2 pl-0 pr-0 position-relative">
                        <div v-if="selectItemToggle">
                            <el-checkbox v-model="selectionModel.itemsSelected" :label="element.id"
                                         @change="handleCheckedItemChange"
                                         class="ml-2 mb-0 p-0"
                                         style="transform: scale(1.4)">
                                <span></span>
                            </el-checkbox>
                        </div>
                        <div v-else class="profile-image-ctn position-relative">
                            <a href="javascript:void(0);"
                               v-bind:class="{ 'deactive-account' : (element.status === 3) }"
                               @click="performAction('viewDetailInfo', element)">
                                <div v-if=" element.profilePhoto !== null && element.profilePhoto !== '' ">
                                    <div v-if="!element.profilePhoto.includes('http')">
                                        <v-avatar style="grayscale(100%); opacity: 0.25;"
                                                  v-if="!element.profilePhoto.includes('data:image')"
                                                  size="3rem">
                                            <img class="profile-image rounded"
                                                 :src="`data:image/jpeg;base64,${element.profilePhoto}`"
                                                 onerror="this.src='/img/avatars/male.png'" />
                                        </v-avatar>
                                        <v-avatar v-else size="3rem">
                                            <img class="profile-image rounded"
                                                 :src="element.profilePhoto"
                                                 onerror="this.src='/img/avatars/male.png'" />
                                        </v-avatar>
                                    </div>
                                    <img v-else
                                         class="profile-image rounded"
                                         :src="element.profilePhoto"
                                         onerror="this.src='/img/avatars/male.png'" />
                                </div>
                                <div v-else>
                                    <v-avatar style="grayscale(100%); opacity: 0.7;" size="3rem">
                                        <img class="profile-image" src="/img/avatars/male.png" />
                                    </v-avatar>
                                </div>
                            </a>
                            <div class="position-absolute d-flex align-items-center top-1 right-1 left-1 bottom-1"
                                 v-if="element.status === 2">
                                <v-avatar size="3rem">
                                    <img src="/img/locking-account.svg" />
                                </v-avatar>
                            </div>
                            <div v-if="isLowbalance(element)"
                                 class="position-absolute d-flex align-items-center top-1 right-1 left-1 bottom-1"
                                 v-bind:class="{ blink_me2s: isLowbalance(element), }">
                                <button type="button"
                                        data-toggle="tooltip"
                                        title="Warning low balance GPU Hub. Click to send mail warning"
                                        @click="performAction('sendMailWarningLowBalance', element)">
                                    <img src="/img/exclamation.svg" style="width: 2.5rem" />
                                </button>
                            </div>
                            <div v-if="element.softwareFocus && element.softwareFocus.isHighlight"
                                 class="badge bg-gradient-warning pt-1 position-absolute"
                                 style="margin-top: -0.5rem; margin-left: -1.2rem; left: 50%; top: 100%;">
                                FOCUS
                            </div>
                        </div> 
                        <div class="ribbon-wrapper ribbon-left" 
                             v-if="element.partnerInfo">
                            <div class="ribbon bg-danger">
                                <span style="font-size: 90%;">{{element.partnerInfo.PartnerCodeName}}</span>
                            </div>
                        </div>
                    </td>
                    <td class="v-center text-nowrap text-left pt-2 pb-2">
                        <div>
                            <div class="d-flex flex-row align-items-center">
                                <div @click="checkAccess('USER_VIEW_DETAIL') || (userInfo.userEmail.toLowerCase() === element.staffTakeCare.toLowerCase()) ? performAction('viewDetailInfo', element) : none()"
                                     style=" color: #007bff; cursor: pointer; font-size: 0.95rem; font-weight: 600; ">
                                    <small class="badge bg-gradient-danger mr-2 pt-1"
                                           v-if="element.status === 3">
                                        De-actived
                                    </small>                                    
                                    <small v-if="element.isUsingDesktopApp"
                                            style="padding-bottom: 2px;"
                                            class="mr-2 pt-0 label bg-gradient-purple">
                                            Desktop
                                    </small>
                                    <span v-if="element.isSystemUser" class="mr-1">
                                        <el-tooltip content="System user" placement="bottom" effect="light">
                                            <i class="el-icon-user text-primary"></i>
                                        </el-tooltip>
                                    </span>
                                    <span>{{ element.userName }}</span>
                                    <span v-if="element.emailVerified"
                                          class="ml-1"
                                          style="color: #f57c00; font-size: 0.8rem">
                                        <el-tooltip content="Email verified" placement="bottom" effect="light">
                                            <i class="fas fa-check-circle text-success"></i>
                                        </el-tooltip>
                                    </span>
                                    <span v-else class="ml-1">
                                        <el-tooltip content="Not verified" placement="bottom" effect="light">
                                            <i class="fas fa-exclamation-circle text-warning"></i>
                                        </el-tooltip>
                                    </span>
                                </div>
                                <div>
                                    <renderSoftwareFocus :userId="element.id" v-model="element.softwareFocus" />
                                </div>
                                <el-tooltip style="width: 0.5rem; margin-left: 0.05rem"
                                            class="item ml-2"
                                            effect="light"
                                            content="Click to copy Username"
                                            placement="right">
                                    <div @click=" performAction('copyToClipboard', { inputId: '#userCopy', data: element.userName, }) "
                                         style="cursor: pointer">
                                        <i style="font-size: 0.75rem"
                                           class="el-icon-document-copy"></i>
                                    </div>
                                </el-tooltip>
                            </div>
                            <div class="d-flex flex-column mt-1">
                                <div class="d-flex align-items-center"
                                     style="font-size: 0.8rem; font-weight: 600">
                                    <img style="height: 1rem"
                                         :src="`/flag/${element.region}.svg`" />
                                    <span class="ml-2">{{ $countryList[element.region] }}</span>
                                    <span class="ml-1" style="font-weight: 500">({{ element.region }})</span>
                                </div>
                                <el-tooltip style="width: 0.5rem; margin-left: 0.05rem"
                                            class="item"
                                            effect="light"
                                            content="Register at:"
                                            placement="left">
                                    <div style="font-size: 0.8rem">
                                        {{ element.createdAt | dateFull }}
                                    </div>
                                </el-tooltip>
                            </div>
                            <div class="mt-1 d-flex align-items-center">                                
                                <div class="mr-1" v-if="element.appCode && appCodeIdentifier[element.appCode]">
                                    <span class="badge bg-gradient-danger p-1">
                                        {{ appCodeIdentifier[element.appCode] ? appCodeIdentifier[element.appCode] : "Web" }}
                                    </span>
                                </div>
                                <div v-if="element.accessLicenseType && element.accessLicenseType === 1"
                                     class="mr-1">
                                     <span class="badge bg-gradient-purple p-1">
                                        MX Node-lock
                                    </span>
                                </div>
                                <div v-if="element.partnerInfo"
                                     class="mr-1">
                                    <span class="badge bg-gradient-danger p-1">
                                        {{element.partnerInfo.PartnerCodeName}}
                                    </span>
                                </div>
                                <div class=""
                                     v-if="userInfo.userEmail.toLowerCase() === element.staffTakeCare.toLowerCase() || (rolesInfo.includes('OWNED') || rolesInfo.includes('QA'))">
                                    <el-dropdown>
                                        <span class="el-dropdown-link">
                                            <button type="button"
                                                    v-if="element.classifyId === null"
                                                    class="badge bg-gradient-secondary"
                                                    style="font-size: 90%;">
                                                <i class="el-icon-plus"></i> add tag
                                            </button>
                                            <button type="button"
                                                    v-else
                                                    class="badge bg-gradient-primary"
                                                    style="font-size: 85%;">
                                                <span v-if="userTagClassifyDict[element.classifyId]">
                                                    <i class="fas fa-tag" style="font-size: 90%;"></i> {{userTagClassifyDict[element.classifyId].classifyName}}
                                                </span>
                                            </button>
                                        </span>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item v-for="item in userTagClassifyList"
                                                              class="p-0"
                                                              :key="item.id">
                                                <button @click="setClassify(element, item)"
                                                        class="pl-3 pr-3">
                                                    <i class="fas fa-tag text-info mr-1"></i> {{item.classifyName}}
                                                </button>
                                            </el-dropdown-item>
                                            <el-dropdown-item class="p-0"
                                                              v-if="element.classifyId !== null">
                                                <button @click="clearClassify(element, { id: element.classifyId })"
                                                        class="pl-3 pr-3 text-danger">
                                                    <i class="el-icon-close"></i> Clear tag
                                                </button>
                                            </el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                                <div v-else>
                                    <span class="badge bg-gradient-primary"
                                          v-if="userTagClassifyDict[element.classifyId]"
                                          style="font-size: 85%;">
                                        <i class="fas fa-tag" style="font-size: 90%;"></i> {{userTagClassifyDict[element.classifyId].classifyName}}
                                    </span>
                                </div>
                            </div>                            
                        </div>
                    </td>
                    <td style="font-size: 0.85rem"
                        class="no-select v-center text-nowrap text-left pt-2 pb-2">
                        <ColumnBalance :userId="element.id" />
                    </td>
                    <td class="no-select v-center text-nowrap pt-2 pb-2">
                        <div>
                            <div @click=" performAction('copyToClipboard', { inputId: '#userCopy', data: element.userCode, }) "
                                 style="cursor: pointer"
                                 class="hover-highlight d-flex flex-row align-items-center">
                                <i class="el-icon-cpu"></i>
                                <div class="ml-2">{{ element.userCode }}</div>
                            </div>
                            <div @click="processCopyEmail(element.staffTakeCare, element.email)"
                                 style="cursor: pointer"
                                 class="hover-highlight d-flex flex-row align-items-center mt-1">
                                <i class="el-icon-message"></i>
                                <del class="ml-2"
                                     style="color: red"
                                     v-if="element.isEmailSpam">{{processEmailUser(element.staffTakeCare, element.email)}}</del>
                                <div v-else class="ml-2">{{processEmailUser(element.staffTakeCare, element.email)}}</div>
                            </div>
                            <div @click="processCopyPhone(element.staffTakeCare, element.phone)"
                                 style="cursor: pointer"
                                 class="hover-highlight d-flex flex-row align-items-center mt-1">
                                <i class="el-icon-phone-outline"></i>
                                <div class="ml-2">{{ processUserPhone(element.staffTakeCare, element.phone)}}</div>
                            </div>
                        </div>
                    </td>
                    <td class="v-center text-nowrap text-left pt-2 pb-2">
                        <div class="d-flex flex-column">
                            <div class="d-flex flex-row align-items-center">

                            </div>
                            <el-tooltip style="width: 5rem; margin-left: 0.05rem"
                                        class="item"
                                        effect="light"
                                        content="Referral from:"
                                        placement="left">
                                <div v-if="element.referalUserName"
                                     class="d-flex flex-row align-items-center mt-1">
                                    <div>
                                        <i class="el-icon-connection"></i>
                                    </div>
                                    <div class="ml-2" style="color: #388e3c; font-weight: 600">
                                        {{ element.referalUserName }}
                                    </div>
                                </div>
                            </el-tooltip>
                            <div class="d-flex flex-row align-items-center mt-1"
                                 v-if="element.nationality">
                                <div>
                                    <i class="el-icon-link"></i>
                                </div>
                                <div class="ml-2" style="color: #e65100">
                                    <span>{{ element.nationality }}</span>
                                    <el-button type="text" 
                                        class="p-0 m-0 ml-1"
                                        v-if="hasChangeSourcePermission()"
                                        @click="openChangeSource(element)">
                                        <i class="el-icon-edit"></i>
                                    </el-button>
                                </div>
                            </div>
                            <div v-else>
                                <span class="text-muted">
                                    <i>[Unset source]</i>
                                </span>
                                <el-button type="text"
                                           class="p-0 m-0 ml-1"
                                           v-if="hasChangeSourcePermission()"
                                           @click="openChangeSource(element)">
                                    <i class="el-icon-plus"></i>
                                </el-button>
                            </div>
                            <div v-if="element.registeredHistory"
                                 class="d-flex flex-row align-items-center mt-1">
                                <el-popover placement="right" width="400" trigger="click">
                                    <VuePerfectScrollbar class="d-flex flex-column m-2"
                                                         style="max-height: calc(57.5vh); padding-right: 0.55rem">
                                        <div v-for="(item, index) in element.registeredHistory"
                                             :key="index"
                                             class="d-flex flex-row justify-content-between align-content-center pt-2 pb-2"
                                             :style="`${((index === element.registeredHistory.length - 1) ? '' : 'border-bottom: 1px solid #eeeeee;')}`">
                                            <div v-if="item.requestId">{{ item.requestId }}</div>
                                            <div v-else style="font-style: italic; opacity: 0.5">
                                                No request ID
                                            </div>
                                            <div>{{ item.createdAt | pretyDateUtc }}</div>
                                        </div>
                                    </VuePerfectScrollbar>
                                    <el-button class="mt-0 p-0"
                                               slot="reference"
                                               style="background-color: transparent; border: none">
                                        <div class="d-flex flex-row align-items-center">
                                            <div>
                                                <i class="el-icon-position"></i>
                                            </div>
                                            <div class="ml-2">
                                                <span style="color: #9e9e9e">Request ID</span>
                                                <span class="ml-2"
                                                      style="font-weight: 600; color: #42a5f5">({{ element.registeredHistory.length }})</span>
                                            </div>
                                        </div>
                                    </el-button>
                                </el-popover>
                            </div>

                            <div class="mt-2" 
                                 v-if="hasOwnerPermission(element)">
                                <el-button type="text" class="pt-0 pb-0"
                                           @click="openModalSelectRenderSoftware(element)">
                                    <div v-if="element.sortwareUsed !== null || element.rendererUsed !== null">
                                        <span class="badge bg-gradient-purple pt-1"
                                              v-if="modelingSoftwareDict[element.sortwareUsed]">
                                            {{modelingSoftwareDict[element.sortwareUsed].name}}
                                        </span>
                                        <span class="badge bg-gradient-fuchsia pt-1 ml-1"
                                              v-if="rendererSoftwareDict[element.rendererUsed]">
                                            {{rendererSoftwareDict[element.rendererUsed].name}}
                                        </span>
                                    </div>
                                    <div v-else>
                                        <span class="badge bg-gradient-gray pt-1">
                                            --- Select sortware ---
                                        </span>
                                    </div>
                                </el-button>
                            </div>
                            <div v-else class="mt-2">
                                <div v-if="element.sortwareUsed !== null || element.rendererUsed !== null">
                                    <span class="badge bg-gradient-purple pt-1"
                                          v-if="modelingSoftwareDict[element.sortwareUsed]">
                                        {{modelingSoftwareDict[element.sortwareUsed].name}}
                                    </span>
                                    <span class="badge bg-gradient-fuchsia pt-1 ml-1"
                                          v-if="rendererSoftwareDict[element.rendererUsed]">
                                        {{rendererSoftwareDict[element.rendererUsed].name}}
                                    </span>
                                </div>
                            </div>

                        </div>
                    </td>
                    <td class="v-center text-nowrap text-center pt-2 pb-2 pl-2 pr-2">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            <div v-if="element.staffTakeCare !== null && element.staffTakeCare !== ''">
                                <el-dropdown v-if="checkAccess('USER_ACTION_CHANGE_STAFF_CARE')">
                                    <strong class="el-dropdown-link">
                                        <v-avatar size="3rem"
                                                  data-toggle="tooltip"
                                                  :title="element.staffTakeCare">
                                            <img id="app-avatar"
                                                 :src="`/img/avatars/${element.staffTakeCare}.jpg`"
                                                 onerror="this.src='/img/avatar5.png'" />
                                        </v-avatar>
                                    </strong>
                                    <el-dropdown-menu slot="dropdown" class="list-staff">
                                        <el-dropdown-item size="small"
                                                          v-if="!isCaptainGroup()">
                                            <button class="d-flex align-items-center pt-1 pb-1 text-left btn-block"
                                                    @click=" performAction('updateUserTakeCare', { item: element, source: { email: 'ngotrunghieu@irender.vn' }, }) ">
                                                <v-avatar size="2.5rem">
                                                    <img id="app-avatar"
                                                         :src="`/img/avatars/ngotrunghieu@irender.vn.jpg`"
                                                         onerror="this.src='/img/avatar5.png'" />
                                                </v-avatar>
                                                <div class="ml-2" style="line-height: 1rem">
                                                    <div><strong>Coolboy195</strong> (coolboy195)</div>
                                                    <div>
                                                        <small>
                                                            <i>ngotrunghieu@irender.vn</i>
                                                        </small>
                                                    </div>
                                                </div>
                                            </button>
                                        </el-dropdown-item>
                                        <el-dropdown-item :key="itemStaff.id"
                                                          size="small"
                                                          v-for="itemStaff in getStaffList()">
                                            <button class="d-flex align-items-center pt-1 pb-1 text-left btn-block"
                                                    @click=" performAction('updateUserTakeCare', { item: element, source: itemStaff, }) ">
                                                <v-avatar size="2.5rem">
                                                    <img id="app-avatar"
                                                         :src="`/img/avatars/${itemStaff.email}.jpg`"
                                                         onerror="this.src='/img/avatar5.png'" />
                                                </v-avatar>
                                                <div class="ml-2" style="line-height: 1rem">
                                                    <div>
                                                        <strong>{{ itemStaff.fullName }}</strong>
                                                        ({{ itemStaff.code }})
                                                    </div>
                                                    <div>
                                                        <small>
                                                            <i>{{ itemStaff.email }}</i>
                                                        </small>
                                                    </div>
                                                </div>
                                            </button>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                                <div v-else>
                                    <v-avatar size="3rem">
                                        <img id="app-avatar"
                                             :src="`/img/avatars/${element.staffTakeCare}.jpg`"
                                             onerror="this.src='/img/avatar5.png'" />
                                    </v-avatar>
                                </div>
                            </div>

                            <div v-else>
                                <div v-if="checkAccess('USER_ACTION_ACCEPT_TAKE_CARE')"
                                     class="d-flex justify-content-center align-items-center"
                                     @click="performAction('acceptTakeCare', element)"
                                     style=" height: 3rem; width: 3rem; border-radius: 1.5rem; border: 1px solid darkorange; cursor: pointer; ">
                                    <i style="color: darkorange; font-size: 1.25rem"
                                       class="el-icon-plus"></i>
                                </div>
                            </div>
                        </div>
                        <!--&& $emailAllowedChangeSource.includes(userInfo.userEmail)-->
                        <div class="text-muted mt-1"
                             v-if="element.staffTakeCare !== null && element.staffTakeCare !== ''">
                            <el-tooltip class="item" effect="light" content="Register at:" placement="left">
                                <div style="font-size: 0.8rem">
                                    {{diffTimeAccept(element.createdAt, element.staffAcceptTime)}}
                                </div>
                            </el-tooltip>
                        </div>
                    </td>
                    <td class="v-center text-nowrap text-left pt-2">
                        <div class="position-relative">
                            <div :id="`note_${element.id}`"
                                 class="d-flex align-items-center mr-2">
                                <div class="cursor-pointer user-note-content p-1 mb-0"
                                     v-if="element.noteContent"
                                     @click="performAction('viewDetailNote', element)">
                                    <div class="d-flex align-items-center">
                                        <v-avatar size="3rem">
                                            <img id="app-avatar"
                                                 :src="`/img/avatars/${element.staffSupport}.jpg`"
                                                 onerror="this.src='/img/avatar5.png'" />
                                        </v-avatar>
                                        <div>
                                            <div class="ml-2 text-muted"
                                                 v-if="rolesInfo.includes('OWNED') || rolesInfo.includes('QA')">
                                                <small>Total {{ element.countNote }} {{ element.countNote > 1 ? 'notes' : 'note' }}, last note {{element.lastNoteCreated | pretyDateUtc}}</small>
                                            </div>
                                            <div>
                                                <div v-html="element.noteContent"
                                                     class="alert bg-light p-1 mb-0 ml-1"
                                                     data-toggle="tooltip"
                                                     title="Click to view more"
                                                     style=" max-width: 15rem; max-height: 2.6rem; overflow: hidden; white-space: normal !important; font-size: 0.8rem; "></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else
                                     class="d-flex justify-content-center align-items-center">
                                    <el-button @click="performAction('editDetailNote', element)"
                                               v-if="checkAccess('USER_ADD_NOTE')"
                                               type="warning"
                                               size="small"
                                               plain
                                               round>
                                        <div>
                                            <i class="el-icon-edit"></i>
                                            <span style="font-weight: 600">Add note</span>
                                        </div>
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="v-center text-nowrap text-right pt-2 pb-2">
                        <div class="d-flex flex-column">
                            <div style="color: #616161; font-size: 0.85rem">
                                {{ element.updatedAt | pretyDateUtc }}
                            </div>
                            <userSameIp :userId="element.id" />
                        </div>
                    </td>
                    <td class="v-center text-nowrap text-center pl-0 pr-0 position-relative">
                        <el-dropdown @command="contextMenuAction"
                                     trigger="click"
                                     placement="bottom-end">
                            <i style="color: #bdbdbd; font-size: 1.5rem"
                               class="el-icon-s-operation p-2 mr-2"></i>
                            <el-dropdown-menu class="no-select" slot="dropdown">
                                <el-dropdown-item disabled
                                                  :command="{ type: 'rename', user: element }">
                                    <a style="text-decoration: none">
                                        <i style="color: forestgreen; margin-right: 0.95rem"
                                           class="el-icon-money"></i>
                                        <span class="font-size-9">Recharge</span>
                                    </a>
                                </el-dropdown-item>
                                <el-dropdown-item disabled
                                                  v-if="checkAccess('USER_ACTION_RESYNC')"
                                                  :command="{ type: 'rename', user: element }">
                                    <a style="text-decoration: none">
                                        <i style="margin-right: 0.95rem"
                                           class="el-icon-refresh"></i>
                                        <span class="font-size-9">Re-sync</span>
                                    </a>
                                </el-dropdown-item>
                                <el-dropdown-item :disabled="false"
                                                  v-if="checkAccess('USER_SETTINGS')"
                                                  :command="{ type: 'user-settings', user: element }"
                                                  divided>
                                    <a style="text-decoration: none">
                                        <i style="margin-right: 0.95rem"
                                           class="el-icon-setting"></i>
                                        <span class="font-size-9">Settings user</span>
                                    </a>
                                </el-dropdown-item>
                                <el-dropdown-item disabled
                                                  v-if="checkAccess('USER_ACTION_BLOCK')"
                                                  :command="{ type: 'rename', user: element }">
                                    <a style="text-decoration: none">
                                        <i style="color: red; margin-right: 0.95rem"
                                           class="el-icon-close"></i>
                                        <span class="font-size-9">Block user</span>
                                    </a>
                                </el-dropdown-item>
                                <el-dropdown-item :command="{ type: 'user-report-user', record: element }">
                                    <a style="text-decoration: none">
                                        <i style="color: red; margin-right: 0.95rem"
                                           class="el-icon-s-flag"></i>
                                        <span class="font-size-9">Report user</span>
                                    </a>
                                </el-dropdown-item>
                                <el-dropdown-item 
                                    :command="{ type: 'change-maxon-license', record: element }">
                                    <a style="text-decoration: none">
                                        <i style="color: red; margin-right: 0.95rem"
                                           class="el-icon-key"></i>
                                        <span class="font-size-9">
                                            {{ element.accessLicenseType && element.accessLicenseType === 1 ? "Set use FLOAT license" : "Set use NODE-LOCK license" }}
                                        </span>
                                    </a>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-else
             class="nodata-container flex-fill d-flex align-items-center justify-content-center h-100">
            <div class="align-self-center text-center">
                <div class="mb-5">
                    <img src="/static/image/no-job-icon.svg" style="opacity: 0.4" />
                </div>
                <h4>
                    <strong class="text-muted">There is no user has been added</strong>
                </h4>
                <p class="text-muted">All iRender users will be show here !</p>
            </div>
        </div>

        <el-dialog title="Select render software"
                   :visible.sync="renderSoftwareDialogVisible"
                   width="30%"
                   center>
            <div>
                <div class="text-center mb-3">
                    <h5 v-if="selectSoftwareModel.userItem">User target <strong>{{selectSoftwareModel.userItem.userName}}</strong></h5>
                </div>
                <div class="d-flex align-content-center justify-content-center align-items-center">
                    <div>
                        <div class="mb-1 text-muted">Modeling software</div>
                        <el-select v-model="selectSoftwareModel.modelingSofwareId" filterable placeholder="Select..">
                            <el-option v-for="item in renderModelingSoftware"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="ml-3">
                        <div class="mb-1 text-muted">Renderer software</div>
                        <el-select v-model="selectSoftwareModel.rendererSoftwareId" filterable placeholder="Select..">
                            <el-option v-for="item in renderRendererSoftware"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <div class="d-flex">
                    <el-button v-if="selectSoftwareModel.userItem && (selectSoftwareModel.userItem.sortwareUsed !== null || selectSoftwareModel.userItem.rendererUsed !== null)"
                               type="danger"
                               @click="clearSelected()">
                        Clear selected
                    </el-button>
                    <div class="flex-fill"></div>
                    <el-button @click="renderSoftwareDialogVisible = false">Cancel</el-button>
                    <el-button type="primary" @click="setModelingRendererSoftware()">
                        Choose
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import EventBus from "@/event/EventBus";
    import lazyLoadComponent from "@/scripts/lazyLoadComponent";
    import SkeletonBoxWithoutLoading from "@/components/SkeletonBoxWithoutLoading";
    import baseComponent from "@/scripts/baseComponent";
    import userTagClassifyApi from '@/api/common/userTagClassify';
    import renderSoftwareApi from '@/api/common/renderSoftware';
    import usersApi from '@/api/common/users';
    import { getlazyLoadInfo } from "@/scripts/_helpers/userHelper";

    const appCodeIdentifier = { 
        "iRenderGPU": "Desktop"
    };

    export default {
        components: {
            ColumnBalance: lazyLoadComponent({
                componentFactory: () => import("@/views/components/column/Balance.vue"),
                loading: SkeletonBoxWithoutLoading,
            }),
            userSameIp: lazyLoadComponent({
                componentFactory: () => import("@/views/components/column/UserSameIp"),
                loading: SkeletonBoxWithoutLoading,
            }),
            renderSoftwareFocus: lazyLoadComponent({
                componentFactory: () => import("@/views/users/RenderSoftwareFocus"),
                loading: SkeletonBoxWithoutLoading,
            }),
        },
        extends: baseComponent,

        props: {
            selectItemToggle: {
                type: Boolean,
                default() {
                    return false;
                }
            },
            selectionModel: {
                type: Object,
                default() {
                    return {
                        isCheckAll: false,
                        itemsSelected: [],
                    };
                }
            },
            elements: {
                type: Array,
                default() {
                    return [];
                },
            },
            listType: {
                type: String,
                default() {
                    return "default";
                },
            },
            noHeaderBorder: {
                type: Boolean,
                default() {
                    return false;
                },
            },
        },
        data() {
            return {
                appCodeIdentifier,
                listTypeCollectionRemove: ["blacklist"],
                isIndeterminate: false,
                renderSoftwareDialogVisible: false,
                renderModelingSoftware: [],
                renderRendererSoftware: [],
                selectSoftwareModel: {
                    userItem: null,
                    userId: null,
                    modelingSofwareId: null,
                    rendererSoftwareId: null,
                }
            };
        },
        computed: {
            ...mapState({
                userInfo: (state) => state.auth.user,
                rolesInfo: (state) => state.auth.roles,
                staffsInfo: (state) => state.staffs,
                lowBalanceUser: (state) => state.lowBalanceUser,
                $emailAllowedChangeSource: (state) => state.emailAllowedChangeSource,
                decentralization: (state) => state.auth.decentralization,
                userTagClassifyList: state => state.common.userClassifyAll,
                userTagClassifyDict: state => state.common.userClassifyAll.reduce(function (acc, cur, i) {
                    acc[cur.id] = cur;
                    return acc;
                }, {})
            }),
            modelingSoftwareDict() {
                let modelingsw = this.renderModelingSoftware.reduce(function (acc, cur, i) {
                    acc[cur.id] = cur;
                    return acc;
                }, {});
                return modelingsw;
            },
            rendererSoftwareDict() {
                let renderersw = this.renderRendererSoftware.reduce(function (acc, cur, i) {
                    acc[cur.id] = cur;
                    return acc;
                }, {});
                return renderersw;
            }
        },
        mounted() {
            this.getSoftwareCategories();
        },
        methods: {
            onWaypoint({ element }) {
                if (!element.hasOwnProperty("isLazyloadData") || !element.isLazyloadData) {
                    getlazyLoadInfo(this, element, element.id);
                    this.$set(element, 'isLazyloadData', true);
                    console.log("onWaypoint");
                }
            },
            isLowbalance(element) {
                return this.$store.getters.lowBalanceUserId.includes(element.id);
            },
            isFocusUser(element) {
                return (element.softwareFocus && element.softwareFocus.isHighlight);
            },
            hasOwnerPermission(element) {
                return this.userInfo.userEmail.toLowerCase() === element.staffTakeCare.toLowerCase() || (this.rolesInfo.includes('OWNED') || this.rolesInfo.includes('QA'));
            },
            hasChangeSourcePermission() {
                return this.isCaptainGroup() || (this.rolesInfo.includes('OWNED') || this.rolesInfo.includes('QA'));
            },
            getStaffList() {
                if (!this.isCaptainGroup()) {
                    return this.staffsInfo.filter(
                        (x) => {
                            return (x.mainDepartment === 'OPERATING_STAFF'
                                && x.status === 1
                                && x.passwordHash !== null
                                && x.passwordHash !== '')
                        }
                    );
                }
                else {
                    if (this.userInfo.groupId) {
                        return this.staffsInfo.filter(
                            (x) => {
                                return (x.mainDepartment === 'OPERATING_STAFF'
                                    //&& x.groupId === this.userInfo.groupId
                                    && x.status === 1
                                    && x.passwordHash !== null
                                    && x.passwordHash !== '')
                            }
                        );
                    }
                    else {
                        return [];
                    }
                }
            },
            handleCheckAllChange(val) {
                this.selectionModel.itemsSelected = val ? this.elements.map(x => x.id) : [];
                this.isIndeterminate = false;
            },
            handleCheckedItemChange() {
                let checkedCount = this.selectionModel.itemsSelected.length;
                this.selectionModel.isCheckAll = checkedCount === this.elements.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.elements.length;
            },
            //Context menu action
            contextMenuAction(command) {
                switch (command.type) {
                    case "user-settings":
                        EventBus.$emit("openPopup", command);
                        break;
                    case "user-report-user":
                        command.username = command.record.userName;
                        EventBus.$emit("openPopup", command);
                        break;
                    case 'change-maxon-license':
                        // eslint-disable-next-line
                        let isUseNodelockLicense = command.record.accessLicenseType && command.record.accessLicenseType === 1 ? false : true;
                        this.setMaxonNodelockLicense(command.record, isUseNodelockLicense);
                        break;
                }
            },
            setMaxonNodelockLicense(userItem, isUseNodelockLicense){
                this.isLoading = true;
                usersApi.setMaxonNodelockLicense(userItem.id, isUseNodelockLicense).then((res) => {
                    try {
                        if (res.data.result === 0) {
                            let isUseNodelockLicenseVal = isUseNodelockLicense ? 1 : 0;
                            this.$set(userItem, "accessLicenseType", isUseNodelockLicenseVal);
                            this.$message({
                                type: 'success',
                                message: 'Perform action completed'
                            });
                        }
                        this.isLoading = false;
                    } catch (error) {
                        console.log("setSelfLicenseUsed -> error", error);
                    }
                })
                .catch(error => {
                    console.log("setSelfLicenseUsed -> error", error);
                });
            },
            setClassify(userItem, classifyItem) {
                userTagClassifyApi.setClassify(userItem.id, classifyItem.id).then((res) => {
                    try {
                        if (res.data.result === 0) {
                            this.$store.dispatch("common/getAllClassify");
                            this.$set(userItem, "classifyId", classifyItem.id);
                            this.$message({
                                type: 'success',
                                message: 'Perform action completed'
                            });
                        }
                        this.isLoading = false;
                    } catch (error) {
                        console.log("setClassify -> error", error);
                    }
                })
                    .catch(error => {
                        console.log("setClassify -> error", error);
                    });
            },
            clearClassify(userItem, classifyItem) {
                userTagClassifyApi.clearClassify(userItem.id, classifyItem.id).then((res) => {
                    try {
                        if (res.data.result === 0) {
                            this.$store.dispatch("common/getAllClassify");
                            this.$set(userItem, "classifyId", null);
                            this.$message({
                                type: 'success',
                                message: 'Perform action completed'
                            });
                        }
                        this.isLoading = false;
                    } catch (error) {
                        console.log("clearClassify -> error", error);
                    }
                })
                    .catch(error => {
                        console.log("clearClassify -> error", error);
                    });
            },
            performAction(actionName, data) {
                this.$emit("actions", { action: actionName, data: data });
            },
            getSoftwareCategories() {
                renderSoftwareApi.getSoftwareCategories().then((res) => {
                    try {
                        if (res.data.result === 0) {
                            this.$set(this, 'renderModelingSoftware', res.data.data.data.filter(x => x.typeOfSoftware === 1));
                            this.$set(this, 'renderRendererSoftware', res.data.data.data.filter(x => x.typeOfSoftware === 2));
                        }
                    } catch (error) {
                        console.log("getSoftwareCategories -> error", error);
                    }
                }).catch(error => {
                    console.log("getSoftwareCategories -> error", error);
                });
            },
            openModalSelectRenderSoftware(userItem) {
                this.$set(this, 'selectSoftwareModel', {
                    userItem: userItem,
                    userId: userItem.id,
                    modelingSofwareId: userItem.sortwareUsed,
                    rendererSoftwareId: userItem.rendererUsed,
                });
                this.renderSoftwareDialogVisible = true;
            },
            setSoftware(userId, modelingSofwareId, rendererSoftwareId) {
                renderSoftwareApi.setModelingRendererSoftware(
                    userId,
                    modelingSofwareId,
                    rendererSoftwareId,
                ).then((res) => {
                    try {
                        if (res.data.result === 0) {
                            this.$set(this.selectSoftwareModel.userItem, 'sortwareUsed', modelingSofwareId);
                            this.$set(this.selectSoftwareModel.userItem, 'rendererUsed', rendererSoftwareId);
                            this.renderSoftwareDialogVisible = false;
                            this.$set(this, 'selectSoftwareModel', {
                                userItem: null,
                                userId: null,
                                modelingSofwareId: null,
                                rendererSoftwareId: null,
                            });
                        }
                    } catch (error) {
                        console.log("getSoftwareCategories -> error", error);
                    }
                }).catch(error => {
                    console.log("getSoftwareCategories -> error", error);
                });
            },
            setModelingRendererSoftware() {
                this.setSoftware(
                    this.selectSoftwareModel.userId,
                    this.selectSoftwareModel.modelingSofwareId,
                    this.selectSoftwareModel.rendererSoftwareId
                );
            },
            clearSelected() {
                this.setSoftware(
                    this.selectSoftwareModel.userId,
                    null,
                    null
                );
            },
            openChangeSource(element) {
                this.$prompt(`Please input source register for <strong>${element.userName}</strong>`, 'Source register', {
                    inputValue: element.nationality,
                    confirmButtonText: 'Save change',
                    cancelButtonText: 'Cancel',
                    dangerouslyUseHTMLString: true,
                    inputPattern: /^([a-zA-Z0-9_.$@#]+)$/,
                    inputErrorMessage: 'Allowed characters: a-z, A-Z, 0-9, _, ., $, #'
                }).then(({ value }) => {
                    usersApi.setRegisterSource(element.id, value).then((res) => {
                        try {
                            if (res.data.result === 0) {
                                this.$set(element, 'nationality', value);
                                this.$message({
                                    type: 'success',
                                    dangerouslyUseHTMLString: true,
                                    message: `<strong>${value}</strong> is new source register on user for <strong>${element.userName}</strong>`,
                                });
                            }
                        } catch (error) {
                            console.log("setRegisterSource -> error", error);
                        }
                    }).catch(error => {
                        console.log("setRegisterSource -> error", error);
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Change source register canceled'
                    });
                });
            },
            getClassBind(element) {
                return {
                    'low-balance': this.isLowbalance(element),
                    'blink-row-tr': this.isFocusUser(element),
                    'partnerUser': element.partnerInfo && element.partnerInfo !== null
                };
            }

        },
    };
</script>

<style>
    .el-dropdown-menu.list-staff {
        overflow-y: auto;
        max-height: 60vh;
    }

    @-webkit-keyframes tr-row-blinking {
        0%, 49% {
            background-color: rgba(255, 52, 52, 0.15);
        }

        50%, 100% {
            background-color: transparent;
        }
    }

    .deactive-account{
        filter: grayscale();
    }
</style>

